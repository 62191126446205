<template>
  <div class="card-info modal-cards" v-if="cardDataAll != null">
    <div
      class="card-content mb-2"
      v-for="(card, index) in cardDataAll"
      :key="card.id"
      ref="selectedCard"
      :class="{ active: index === activeItem }"
      @click="handleClick(card, index)"
    >
      <div class="card-wrapper">
        <div class="img" v-if="card.brand === 'Visa'">
          <img src="../../assets/images/visa.png" alt="" />
        </div>
        <div class="img" v-else-if="card.brand === 'MasterCard'">
          <img src="../../assets/images/mastercard.png" alt="" />
        </div>
        <div class="img" v-else>
          <p>{{ card.brand }}</p>
        </div>
        <div class="card-number">
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span>{{ card.last4 }}</span>
        </div>
      </div>
      <div class="holder-info">
        <h6>{{ card.name }}</h6>
        <span>Exp {{ card.exp_month }}/{{ card.exp_year }}</span>
      </div>
      <button ref="btn" @click="handleClick(card, index)" class="d-none"></button>
    </div>

    <!-- <div>{{ cardData }}</div> -->
  </div>
</template>

<script>
export default {
  name: "CardPayments",
  props:{
    cardDataAll: Array
  },
  data: function() {
    return {
      cardData: "",
      activeItem: 0,
    };
  },
  methods: {
    handleClick: function(card, index) {
      this.activeItem = index;
      this.$root.$emit("getCard", card);
    },
    
  },
  mounted() {
    var elem = this.$refs.Btn;
      if (elem) {
        elem.click();
      }
  },
};
</script>

<style scoped>
.active {
  background: #58595b;
  color: #ffffff;
}
</style>
