<!-- <template>
  <div class="modal-card-details">
    <h6>Charges</h6>
    <div class="plan-info">
      <div v-if="showCharges == 1">
        <p class="mt-2">{{ packDetail.title }}  ${{ packDetail.amount }}</p>
        <small class="text-success" v-if="percentage != ''">Promo code enabled ({{percentage}}% OFF)</small><br>
        <span v-if="percentage != ''">${{ packDetail.amount - (((percentage / 100) *  packDetail.amount).toFixed(2))}} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
        <span v-else>${{ packDetail.amount }} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
      </div>
      <div v-if="showCharges == 3">
        <p class="mt-2">{{ packDetail.title }} ${{ packDetail.discounted_amount }}</p>
        <small class="text-success" v-if="percentage != ''">Promo code enabled ({{percentage}}% OFF)</small><br>
        <span v-if="percentage != ''">${{ packDetail.discounted_amount -(((percentage / 100) * packDetail.discounted_amount).toFixed(2))}} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
        <span v-else>${{ packDetail.discounted_amount }} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
      </div>
      <div class="renewal-wrapper bg-white border-0 p-0 m-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist" >
          <li class="nav-item" v-for="(pack, index) in packages"
            :key="pack.id">
            <a
              class="nav-link"
              data-toggle="tab"
              role="tab"
              aria-selected="true"
              :class="{ active: index === activePack }"
              @click="checkCharges(pack, pack.duration, index)"
              >{{ pack.duration_in_words }}<span v-if="showAnnual">1</span></a
            >
            
            <button ref="btn" @click="checkCharges(pack, pack.duration, index)" class="d-none"></button>
          </li>
        </ul>
        
      </div>
    </div>
  </div>
</template> -->
<template>
  <div class="modal-card-details">
    <h6>Charges</h6>
    
    <div class="plan-info">
      <div v-if="showCharges == 1 ">
        <p class="mt-2">{{ packDetail.title }}  ${{ packDetail.amount }}</p>
        <small class="text-success" v-if="percentage != ''">Promo code enabled ({{percentage}}% OFF)</small><br>
        <span v-if="percentage != ''">${{ packDetail.amount - (((percentage / 100) *  packDetail.amount).toFixed(2))}} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
        <span v-else>${{ packDetail.amount }} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
      </div>
      <div v-if="showCharges == 3 ">
        <p class="mt-2">{{ packDetail.title }} ${{ packDetail.discounted_amount }}</p>
        <small class="text-success" v-if="percentage != ''">Promo code enabled ({{percentage}}% OFF)</small><br>
        <span v-if="percentage != ''">${{ packDetail.discounted_amount -(((percentage / 100) * packDetail.discounted_amount).toFixed(2))}} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
        <span v-else>${{ packDetail.discounted_amount }} <small v-if="packDetail.discount_percentage != 0">({{packDetail.discount_percentage}}% OFF)</small></span>
      </div>
      
      <div class="renewal-wrapper bg-white border-0 p-0 m-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li v-if="showAnnual" class="nav-item">
            <a class="nav-link active" data-toggle="tab" role="tab" aria-selected="true">{{ packages[0].duration_in_words }}</a>
          </li>
          <li v-else class="nav-item" v-for="(pack, index) in packages" :key="pack.id">
            <a class="nav-link" data-toggle="tab" role="tab" :class="{ active: index === activePack }" @click="checkCharges(pack, pack.duration, index)">{{ pack.duration_in_words }}</a>
          </li>
        </ul>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PackagesTabs",
  data: function() {
    return {
      activePack: 0,
      errorMessage:"",
      showCharges: 1,
      packages:"",
      percentage:"",
      packDetail:{
        title:"",
        amount:'',
        discount_percentage:"",
        discounted_amount:""
      }
    };
  },
  props: {
    showAnnual: {
      type: Boolean,
    }
  },

  methods: {
    getData() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}package/listing`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {       
            this.packages = response.data.data;
              this.packDetail.title = this.packages[0].title;
              this.packDetail.amount = this.packages[0].amount;
              this.packDetail.discount_percentage = this.packages[0].discount_percentage;
              this.packDetail.discounted_amount = this.packages[0].discounted_amount;
              this.checkCharges(this.packages[1],'3',1)       
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    checkCharges(pack ,id,index) {
      this.activePack = index;
      this.showCharges = id;
      this.packDetail.title = pack.title;
      this.packDetail.amount = pack.amount;
      this.packDetail.discount_percentage = pack.discount_percentage;
      this.packDetail.discounted_amount = pack.discounted_amount;
      this.$root.$emit("getpack", pack);

    },
  },
  mounted(){
    this.$root.$on("updatePrice", (percentage) => {
      this.percentage = parseFloat(percentage);
    });
    this.getData();
    var elem = this.$refs.Btn;
      if (elem) {
        elem.click();
      }
  },
  watch: {
    showAnnual(val) {
      if (val) {
        this.checkCharges(this.packages[0], "1", 0);
      } else {
        this.checkCharges(this.packages[0], "3", 0);
      }
    },
  }
};
</script>

<style></style>
