<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#CardDetailModal"
      ref="openCardDetailModal"
    ></button>
    <div
      class="modal fade"
      id="CardDetailModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">
              {{ modalTitle }}
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalDetail"
              @click="clearForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- v-if="status == true" -->
            <form>
              <div class="registry-sign-up-form">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="customer_name"
                            >Full Name <span class="astrick">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="full_name"
                            placeholder="Enter Full Name"
                            autocomplete="off"
                            v-model.trim="$v.details.fullname.$model"
                            :class="{
                              'is-invalid': $v.details.fullname.$error,
                              'is-valid': !$v.details.fullname.$invalid,
                            }"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.fullname.required"
                              >Full Name is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="contact_person"
                            >Email <span class="astrick">*</span>
                          </label>
                          <input
                            type="email"
                            v-model.trim="$v.details.email.$model"
                            class="form-control"
                            id="email"
                            placeholder="Enter Email"
                            :class="{
                              'is-invalid': $v.details.email.$error,
                              'is-valid': !$v.details.email.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.email.required"
                              >Email is required</span
                            >
                            <span v-if="!$v.details.email.email"
                              >Email must be valid
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 p-0">
                        <section class="row payment-form">
                          <!-- <div
                            v-if="stripeValidationError != null"
                            class="error red center-align white-text"
                          >
                            {{ stripeValidationError }}
                          </div>
                          <div v-else></div> -->

                          <div class="col-12 card-element form-group p-0">
                            <label>Card Number</label>
                            <div
                              id="card-number-elem"
                              class="input-value"
                            ></div>
                          </div>
                          <div
                            class="col-12 col-lg-6 card-element form-group p-0"
                          >
                            <label>Expiry Date</label>
                            <div id="card-expiry-elem"></div>
                          </div>

                          <div
                            class="col-12 col-lg-6 card-element form-group p-0 pl-lg-2"
                          >
                            <label>CVC</label>
                            <div id="card-cvc-elem"></div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!-- v-else :cardDataAll="cardDataAll.data" -->
            <Cardpayments />
            <div class="row">
              <div class="col-10 p-0">
                <div class="form-group pr-2">
                  <label for="contact_person"
                    >Promo Code <span class="astrick"></span>
                  </label>
                  <input
                    type="text"
                    v-model.trim="$v.promo.$model"
                    class="form-control"
                    id="promo"
                    placeholder="Enter Promo"
                    autocomplete="off"
                    ref="promoInput"
                    :class="{
                      'is-invalid': $v.promo.$error,
                      'is-valid': false, // Always set 'is-valid' to false
                    }"
                  />

                  <!-- <input
                    type="text"
                    v-model.trim="$v.promo.$model"
                    class="form-control"
                    id="promo"
                    placeholder="Enter Promo"
                    autocomplete="off"
                    ref="promoInput"
                    :class="{
                      'is-invalid': $v.promo.$error,
                      'is-valid': !$v.promo.$invalid,
                    }"
                  /> -->
                  <div class="invalid-tooltip mr-2">
                    <span v-if="!$v.promo.required"
                      >Do you have promo code?</span
                    >
                  </div>
                </div>
              </div>
              <button
                class="col-2 p-0 custom-btn mt-4 mb-3 float-right"
                @click="checkPromoCode"
              >
                Check
              </button>
            </div>

            <PackagesTabs :showAnnual="showAnnual" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="createPayment(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="clearForm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import Cardpayments from "../billing/CardPayments.vue";
import PackagesTabs from "./PackagesTabs.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { Cardpayments, PackagesTabs },
  name: "PaymentModal",
  data: function() {
    return {
      details: {
        fullname: "",
        email: "",
      },
      status: false,
      packages: "",
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      isLoadingArray: [],
      stripeValidationError: null,
      modalTitle: "",
      cardData: "",
      packData: "",
      showAnnual: false,
      cardDataAll: [],
      promo: "",
      promoValid: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },

  validations: {
    details: {
      fullname: {
        required,
      },
      email: {
        required,
        email,
      },
    },
    promo: {
      //  required,
    },
  },
  watch: {
    promo(newValue) {
      if (newValue !== "") {
        this.showAnnual = true;
      } else {
        this.showAnnual = false;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    createPayment: function(index) {
      if (this.promo == "") {
        var years = new Array();
        let current = moment().add(1, "days");
        for (var i = 0; i < 1095; i++) {
          years.push(current.format("YYYY-MM-DD"));
          current = current.add(1, "days");
        }
        var next_renewal = years[1094];
        if (this.cardData == "") {
          var formData = new FormData();
          formData.append("payment_amount", this.payment_amount);

          this.$v.$touch();
          if (this.$v.details.$pendding || this.$v.details.$error) return;
          this.$set(this.isLoadingArray, index, true);
          this.stripe.createToken(this.cardNumberElement).then((result) => {
            if (result.error) {
              this.stripeValidationError = result.error.message;
              this.$swal({
                title: this.stripeValidationError,
                text:
                  "Please enter credit card information to complete this step.",
                icon: "info",
                showCancelButton: false,
              }).then(() => {
                this.$set(this.isLoadingArray, index, false);
              });
            } else {
              var stripeObject = {
                amount: this.packData.discounted_amount,
                source: result.token,
                description:
                  this.packData.title + " - " + this.packData.duration,
                name: this.details.fullname,
                package_id: this.packData.id,
                plan_start: new Date(),
                last_renewal: new Date(),
                next_renewal: next_renewal,
                promo_code: this.promo,
              };
              this.saveData(stripeObject, index);
            }
          });
        } else {
          this.$set(this.isLoadingArray, index, true);
          var stripeObject = {
            amount: this.packData.discounted_amount,
            card_id: this.cardData.id,
            package_id: this.packData.id,
            plan_start: new Date(),
            last_renewal: new Date(),
            next_renewal: next_renewal,
            description: this.packData.title + " - " + this.packData.duration,
            promo_code: this.promo,
          };
          this.saveData(stripeObject, index);
        }
      } else if (this.promo != "" && this.promoValid == false) {
        this.$swal(
          "Click on Check!",
          "Please check promo code first to enable the promo code.",
          "info"
        );
      } else if (this.promo != "" && this.promoValid == true) {
        var years = new Array();
        let current = moment().add(1, "days");
        for (var i = 0; i < 1095; i++) {
          years.push(current.format("YYYY-MM-DD"));
          current = current.add(1, "days");
        }
        var next_renewal = years[1094];
        if (this.cardData == "") {
          var formData = new FormData();
          formData.append("payment_amount", this.payment_amount);

          this.$v.$touch();
          if (this.$v.details.$pendding || this.$v.details.$error) return;
          this.$set(this.isLoadingArray, index, true);
          this.stripe.createToken(this.cardNumberElement).then((result) => {
            if (result.error) {
              this.stripeValidationError = result.error.message;
            } else {
              var stripeObject = {
                amount: this.packData.discounted_amount,
                source: result.token,
                description:
                  this.packData.title + " - " + this.packData.duration,
                name: this.details.fullname,
                package_id: this.packData.id,
                plan_start: new Date(),
                last_renewal: new Date(),
                next_renewal: next_renewal,
                promo_code: this.promo,
              };
              this.saveData(stripeObject, index);
            }
          });
        } else {
          this.$set(this.isLoadingArray, index, true);
          var stripeObject = {
            amount: this.packData.discounted_amount,
            card_id: this.cardData.id,
            package_id: this.packData.id,
            plan_start: new Date(),
            last_renewal: new Date(),
            next_renewal: next_renewal,
            description: this.packData.title + " - " + this.packData.duration,
            promo_code: this.promo,
          };
          this.saveData(stripeObject, index);
        }
      }
    },
    createAndMountFormElements() {
      var elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber");
      this.cardNumberElement.mount("#card-number-elem");

      this.cardExpiryElement = elements.create("cardExpiry");
      this.cardExpiryElement.mount("#card-expiry-elem");

      this.cardCvcElement = elements.create("cardCvc");
      this.cardCvcElement.mount("#card-cvc-elem");
    },
    saveData(stripeObject, index) {
      this.getUserData();
      axios
        .post(
          process.env.VUE_APP_API_URL + "payment/sendStripeToken",
          stripeObject
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$swal(
              "Subscription Activated!",
              "Your subscription has been activated successfully.",
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                const enterpriseDomain = localStorage.getItem("domainData");
                const domainData = JSON.parse(enterpriseDomain);

                console.log(domainData.domain_name);

                // Display the second Swal with the domain
                this.$swal(
                  "Domain: " + domainData.domain_name,
                  "This is your domain.",
                  "info"
                ).then(() => {
                  this.$router.push({ name: "EnterpriseLogin" });

                  //for local testing

                  // const currentUrl = window.location.href;
                  // const parts = currentUrl.split("/");
                  // const baseUrl = `${parts[0]}//${parts[2]}`;
                  // const newUrl = baseUrl + "/" + domainData.domain_name + '/login'
                  // window.location.href = newUrl;

                  //for staging
                  // const currentUrl = window.location.href;
                  // const parts = currentUrl.split("/");
                  // if (parts.length >= 3) {
                  //   parts[2] = domainData.domain_name;
                  //   const newUrl = parts.join("/") + "/login";
                  //   window.location.href = newUrl;
                  // } else {
                  //   console.error("The URL is not in the expected format.");
                  // }

                  setTimeout(() => {
                    this.$root.$emit("autoUpdatePack");
                    this.$root.$emit("autoUpdate");
                    this.$root.$emit("reNewSubs");
                  }, 500);
                  this.loader = false;
                  var elem = this.$refs.closeModalDetail;
                  if (elem) {
                    elem.click();
                    this.clearForm();
                  }
                });
              }
            });

            // ... (the rest of your code)
          } else {
            this.$swal(response.data.message, "Please Try again.", "error");
            this.$set(this.isLoadingArray, index, false);
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert("Something went wrong");
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },

    getCards() {
      //api call
      axios
        .post(process.env.VUE_APP_API_URL + "payment/viewTransaction")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.cardDataAll = response.data.card;
            if (this.cardDataAll.data && this.cardDataAll.data.length > 0) {
              this.$root.$emit("getCard", this.cardDataAll.data[0]);
              // this.status = false;
            } else {
              // this.status = true;
            }
          } else {
            // alert("Something went wrong");
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          // alert("Something went wrong");
        });
    },
    getInputs() {
      this.stripe = Stripe(this.$stripePublicKey);
      // if (this.status == true) {
      this.createAndMountFormElements();
      // }
    },
    clearForm() {
      this.details.fullname = "";
      this.details.email = "";
      this.promo = "";
      this.showModal = false;
      this.$v.$reset();
      this.isLoadingArray = false;
    },
    checkPromoCode() {
      this.$v.$touch();
      if (this.$v.promo.$pendding || this.$v.promo.$error) return;
      var formData = new FormData();
      formData.append("promo_code", this.promo);
      axios
        .post(process.env.VUE_APP_API_URL + "payment/verifyPromo", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$root.$emit("updatePrice", response.data.data.percentage);
            this.promoValid = true;
            this.$swal("Promo!", response.data.message, "success");
          } else {
            this.promoValid = false;
            this.$swal("Promo!", response.data.message, "error");
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.getCards();
    this.$root.$on("openCardDetailModal", (Card, modalTitle) => {
      var elem = this.$refs.openCardDetailModal;
      if (elem) {
        elem.click();
      }
      this.getInputs();
      this.modalTitle = modalTitle;
    });

    this.$root.$on("getCard", (Card) => {
      this.cardData = Card;
    });
    this.$root.$on("getpack", (pack) => {
      this.packData = pack;
    });
  },
};
</script>

<style scoped>
label,
input,
input::placeholder,
input.form-control:focus {
  color: #383838;
}

input.form-control {
  background-color: transparent !important;
  border: 1px solid var(--light-gray);
}

input {
  padding-left: 10px;
  font-weight: 400;
}

#card-number-elem,
#card-expiry-elem,
#card-cvc-elem {
  background: white;
  padding: 15px 10px 0 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 45px !important;
  line-height: 45px !important;
}
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
}
.modal-header {
  color: #4d4d4d;
}
</style>
